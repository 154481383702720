<template>
    <div>
        <div class="detail">
            <img src="~assets/img/detail/wuchuang_01.png" alt="">
            <img src="~assets/img/detail/wuchuang_02.png" alt="" @click="bottomClick">
            <img src="~assets/img/detail/detail_04.gif" alt="">
            <img src="~assets/img/detail/detail_05.gif" alt="">
            <img src="~assets/img/detail/detail_06.gif" alt="">
            <img src="~assets/img/detail/detail_07.gif" alt="">
        </div>
        <div class="code" v-show="isShow" @touchmove.prevent>
            <img src="~assets/img/code.png" alt="" @click="imgClick">
        </div>
        <div class="bottom">
            <div class="click" @click="bottomClick">
                预约咨询
            </div>
        </div>
    </div>
</template>


<script>
    import { wxShareMixin } from "common/mixin";

    export default {
        name: "wuChuang",
        data() {
            return {
                isShow: false
            }
        },
        mixins: [wxShareMixin],
        created() {
            this.wxShare()
            this.wxShareMessage('无创亲子鉴定',
                '无创的产前亲子鉴定是目前提取过程中最安全、无风险、最简单的鉴定。',
                '/img/shengwu.png')
        },
        methods: {
            bottomClick() {
                this.isShow = !this.isShow
            },
            imgClick() {
                this.isShow = !this.isShow
            }
        }
    }
</script>

<style scoped>
    .detail{
        margin-bottom: 9%;
        font-size: 0;
    }
    .detail img {
        width: 100%;
    }

    .code{
        background-color:rgba(0,0,0,0.8);
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        /*width: 56vh;*/
        height: 100%;
        z-index: 99;
    }
    .code img{
        width: 80%;
        position: relative;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
    .bottom{
        height: 9%;
        background-color: #fff;
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
    }
    .click{
        background-color: #7db9ff;
        width: 55%;
        height: 66%;
        color: #ffffff;
        font-size: 2.5vh;
        position: relative;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        border-radius: 80px;
        display: flex;
        text-align: center;
        flex-direction: column;
        justify-content: center;
    }


</style>